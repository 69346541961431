import Cookies from 'js-cookie';

$(".module--tableofcontents tr").on("click", function () {
    window.location = $("a", this).eq(0).attr("href");
    return false;
});

var tableofcontents = {
    cookieName: 'shs_history',
    list: "",
    construct: function () {
        var obj = this;
        obj.list = Cookies.get(this.cookieName);
        obj.log('init: ' + this.list);
        obj.list = (this.list ? this.list.split('|') : []);
        obj.update();
        return this;
    },
    add: function (value) {
        var obj = this;
        obj.found = false;
        if (!value)
            return;
        $.each(obj.list, function (index, element) {
            if (element == value) {
                obj.found = true;
                return;
            }
        });
        if (!obj.found) {
            obj.list.push(value);
            obj.save();
        }
    },
    save: function () {
        var obj = this;
        Cookies.set(obj.cookieName, obj.list.join('|'), {expires: 30, path: '/'});
        obj.update();
    },
    reset: function () {
        var obj = this;
        obj.list = [];
        obj.save();
    },
    update: function () {
        var obj = this;
        if (obj.list.length > 0) {
            $.each(obj.list, function (index, element) {
                $('tr[data-id="' + element + '"]:not([class~="module--tableofcontents__selected"])').addClass('module--tableofcontents__selected');
            });
        }
    },
    log: function (message) {
        try {
            console.log('shs_history: ' + message);
        } catch (err) {
        }
    }
};

tableofcontents.construct();
tableofcontents.add(wpPostid);
